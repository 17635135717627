import { DateTime } from "luxon";
import { ApiError, UserPartialItem } from "@/service";
import { ProposedTimesListItem } from "@/models";

export function formatMoney(amount: number): string {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(amount);
}

export function timestampToLocalDate(timestamp: number | undefined, format: string | undefined = undefined): string {
  if (!timestamp) return "";
  if (format && format.length) {
    return DateTime.fromSeconds(timestamp).toFormat(format);
  }
  return DateTime.fromSeconds(timestamp).toLocaleString();
}

export function timestampToLocalDateTime(timestamp: number | undefined): string {
  if (!timestamp) return "";
  return DateTime.fromSeconds(timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
}
 
export function formatTime(time: string | undefined | null): string {
  if (!time) return "";
  return DateTime.fromFormat(time, "HH:mm:ss").toLocaleString(DateTime.TIME_SIMPLE);
}

export function parseApiError(error: Error | ApiError): string {
  if (error) {
    const e = error as ApiError;
    if (e && e.body && e.body.message){
      return e.body.message;
    } else if ((error as Error).message) {
      return (error as Error).message;
    }
    return "Unknown error";
  }
  return "";
}

export function getResolvedUserName(u?: UserPartialItem): string | undefined {
  if (!u || !u.id) return undefined;
  return u.resolvedDisplayName ?? `${u.firstName} ${u.lastName}`;
}

export function generateProposedTimes(startDate?: DateTime, startHour: number = 0, endHour = 24, days: number = 0, weeks: number = 3): Array<ProposedTimesListItem> {
  const daysCount = days + (weeks * 7);
  let date = startDate ?? DateTime.now();
  let timeList = Array<ProposedTimesListItem>();
  for(let i=0; i < daysCount; i++) {
    let timeListItem = {} as ProposedTimesListItem;
    date = date.set({ hour: startHour, minute: 0, second: 0});
    let dateStart = date;
    timeListItem.name = date.toFormat("ccc\nLL/dd");
    timeListItem.value = { 
      short: date.toFormat("ccc"),
      long: date.toFormat("cccc"),
      date: date.toFormat("LL/dd"),
      times: []
    };
    // prevent infinite loops when times cross days
    while (date.hour <= endHour && date.day===dateStart.day) {
      console.log('Day: ' + date.day + ' Hour: ' + date.hour + ' Min: ' + date.minute);
      timeListItem.value.times.push({ts: String(Math.floor(date.toSeconds())), time: date.toFormat("hh:mm a").toLocaleLowerCase()});
      if (date.minute === 30) {
        if (date.hour === 23){
          break;
        }
        date = date.set({ hour: date.hour + 1, minute: 0 });
      } else {
        date = date.set({minute: 30});
      }
    }
    timeList.push(timeListItem);
    date = date.set({ day: date.day + 1 });
  }
  return timeList;
}

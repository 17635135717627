/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { logRefTable } from './logRefTable';

/**
 * Log Field Change
 */
export type LogFieldChangeItem = {
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Reference Record Unique ID
     */
    refId?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    logRefTable?: logRefTable;
    /**
     * Reference event
     */
    event?: LogFieldChangeItem.event;
    /**
     * Reference Field
     */
    refField?: string;
    /**
     * Text
     */
    text?: string;
    /**
     * Old Value
     */
    oldVal?: string;
    /**
     * New Value
     */
    newVal?: string;
    /**
     * User Id
     */
    userId?: number;
    /**
     * API Response Version
     */
    _ver?: number;
}

export namespace LogFieldChangeItem {

    /**
     * Reference event
     */
    export enum event {
        FIELD_CHANGE = 'field_change',
        QUERY_CREATE = 'query_create',
        LOGIN = 'login',
        LOGIN_AS = 'login_as',
        ENTITY_ENABLE_DISABLE = 'entity_enable_disable',
        UPDATE = 'update',
        CREATE = 'create',
        DELETE = 'delete',
    }


}

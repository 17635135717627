/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Reference Table
 */
export enum logRefTable {
    UNKNOWN = 'unknown',
    QUERY_HEADER = 'query_header',
    USER = 'user',
    GRANT_PROGRAM = 'grant_program',
    GRANT_PROGRAM_DIAGNOSIS = 'grant_program_diagnosis',
    GRANT_PROGRAM_DIAG_DOMAINS = 'grant_program_diag_domains',
    GRANT_PROGRAM_POOLS = 'grant_program_pools',
    POOLS = 'pools',
    POOLS_PLANS_HASH = 'pools_plans_hash',
    POOLS_EXPERTS_HASH = 'pools_experts_hash',
    POOLS_DIAGNOSIS_HASH = 'pools_diagnosis_hash',
    MEMBERSHIP_PLAN = 'membership_plan',
    PLAN_GRANT_HASH = 'plan_grant_hash',
    MEMBERSHIP_PLAN_LINKS = 'membership_plan_links',
    MEMBERSHIP_PLAN_SURGE = 'membership_plan_surge',
    MEMBERSHIP_CENSUS = 'membership_census',
    USER_INVITE = 'user_invite',
    ADDRESS = 'address',
    PHONE = 'phone',
    NOTIFICATION_PREFERENCE = 'notification_preference',
    USER_BIO = 'user_bio',
    USER_AWAY = 'user_away',
    CANCER_SPECIALIZATIONS = 'cancer_specializations',
    MASTER_CANCER_TYPE = 'master_cancer_type',
    MASTER_CANCER_GROUP = 'master_cancer_group',
    DIAGNOSIS_DOMAIN = 'diagnosis_domain',
}